<template>
  <mu-container>
    <mu-row gutter>
      <mu-col span="12" md="8">
        <template v-if="threads">
          <ThreadsList :threads='threads' :loading='loading'/>
          <Pagination :totalPage='totalPage' :currentPage='page'/>
        </template>
        <mu-container v-else-if="loading" class="loading">
            <mu-expansion-panel v-loading="loading" data-mu-loading-size="24"></mu-expansion-panel>
        </mu-container>
      </mu-col>
      <mu-col span="12" md="4">
        <ShoppingCart/>
      </mu-col>
    </mu-row>
  </mu-container>
</template>

<script>
import { mapState } from 'vuex'
import ThreadsList from '@/components/ThreadsList.vue'
import Pagination from '@/components/Pagination.vue'
import ShoppingCart from '@/components/ShoppingCart.vue'

export default {
  name: 'PageThreads',
  data () {
    return {
      threads: null,
      totalPage: 1,
      fid: this.$route.params.fid,
      page: Number(this.$route.query.page) || 1,
      loading: false
    }
  },
  computed: {
    ...mapState(['cartedThreads'])
  },
  components: {
    ThreadsList, Pagination, ShoppingCart
  },
  mounted () {
    this.loading = true
    this.fetchData()
  },
  watch: {
    $route (to, from) {
      this.page = this.$route.query.page
      this.loading = true
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      const url = `${process.env.VUE_APP_API}/thread/fid/${this.fid}/page/${this.page}/per_page/${process.env.VUE_APP_PERPAGE}/`
      this.$axios
        .get(url)
        .then(response => {
          this.loading = false
          this.threads = response.data.threads
          this.totalPage = Number(response.data.pageCount)
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          if (error.response.status === 401) {
            this.$toast.error('未登录')
            this.$router.push({ path: '/login' })
          } else {
            this.$toast.error('获取数据失败')
          }
        })
    },
    // 购物车
    addToCart (thread) {
      this.$store.commit('addToCart', thread)
    }
  }
}
</script>

<style lang="stylus" scoped>
.loading
  position relative
  top 40px
</style>
